<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import axios from 'axios';
import { required, email, minLength } from "vuelidate/lib/validators";
import appConfig from "@/app.config";
import Swal from "sweetalert2";

export default {
  page: {
    title: "Administradores",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader},
  data() {
    return {
      title: "Administradores",
      items: [
        {
          text: "Administradores",
          active: true
        }
      ],
      admins: [],
      customers: {
        name: "",
        email: "",
        password: "",
      },
      submitted: false,
      showmodal: false,
      user: null,
      checked: true,
      loading: true
    };
  },
  validations: {
    customers: {
      name: { required },
      email: { required, email },
      password: { required, minLength: minLength(6) },
    }
  },
  created(){
    this.user = JSON.parse(localStorage.getItem('user'));
    this.getData()
  },
  methods:{
    getData(url){
      this.loading = true
      let config = {
        method: 'get',
        url: url ? url : `${process.env.VUE_APP_API}api/v1/admin`,
        headers: { 
          'Accept': 'application/json', 
          'Authorization': `Bearer ${this.user.access_token}`
        }
      };

      axios(config)
      .then( (response) => {
        this.admins = response.data.data
        this.loading = false
        this.admins.data.map((item, index) => {
          this.admins.data[index].checked = item.status == 1 ? true : false
        })
      })
      .catch(function (error) {
        console.log(error);
      });
    },
    /**
     * Modal form submit
     */
    // eslint-disable-next-line no-unused-vars
    handleSubmit(e) {
      this.submitted = true;

      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        const name = this.customers.name;
        const email = this.customers.email;
        const password = this.customers.password;

        let config = {
          method: 'post',
          url: `${process.env.VUE_APP_API}api/v1/admin/register`,
          headers: { 
            'Accept': 'application/json', 
            'Authorization': `Bearer ${this.user.access_token}`
          },
          data: {
            email: email,
            password: password,
            name: name
          }
        };
        
        axios(config)
        .then( () => {
          this.getData()
          this.$bvToast.toast('Administrador adicionado com sucesso', {
            title: 'Tudo certo!',
            variant: 'success',
            solid: true
          });
          this.showmodal = false;
          this.submitted = false;
          this.customers = {};
        })
        .catch( () => {
          this.$bvToast.toast('Verifique os campos digitados', {
            title: 'Ocorreu um erro',
            variant: 'danger',
            solid: true
          });
        });
      }
      
    },
    /**
     * hode mondal on close
     */
    // eslint-disable-next-line no-unused-vars
    hideModal(e) {
      this.submitted = false;
      this.showmodal = false;
      this.contacts = {};
    },

    /**
     * Filter the data of search
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    changeStatus(status, id){
      let config = {
        method: 'PUT',
        url: `${process.env.VUE_APP_API}api/v1/admin/${id}/change-status`,
        headers: { 
          'Accept': 'application/json', 
          'Authorization': `Bearer ${this.user.access_token}`
        },
        data:{
          enable: status
        }
      };

      axios(config)
      .then( (response) => {
        response
      })
    },
    deleteAdmin(id){
      Swal.fire({
        title: "Tem certeza?",
        text: "Esse registro será deletado permanentemente!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Sim, excluir!",
        cancelButtonText: "Cancelar!"
      }).then(result => {
        if (result.value) {
           let config = {
            method: 'DELETE',
            url: `${process.env.VUE_APP_API}api/v1/admin/${id}`,
            headers: { 
              'Accept': 'application/json', 
              'Authorization': `Bearer ${this.user.access_token}`
            }
          };
          axios(config)
          .then( () => {
            this.getData()
            Swal.fire("Excluído!", "O registro já foi removido.", "success");
          })
          
        }
      });
    },
    paginate(value){
      let link = this.admins.links[value].url
      this.getData(link)
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          
          <div class="card-body">
            <div>
              <a href="javascript:void(0);" class="btn btn-success mb-2" @click="showmodal = true">
                <i class="mdi mdi-plus mr-2"></i> Adicionar administrador
              </a>
            </div>
            <div class="table-responsive mt-3">
              <b-overlay :show="loading" rounded="sm">
                <table
                  class="table table-centered datatable dt-responsive nowrap"
                  style="border-collapse: collapse; border-spacing: 0; width: 100%;"
                >
                  <thead class="thead-light">
                    <tr>
                      <th style="width: 20px;">
                        <div class="custom-control custom-checkbox">
                          <input type="checkbox" class="custom-control-input" id="customercheck" />
                          <label class="custom-control-label" for="customercheck">&nbsp;</label>
                        </div>
                      </th>
                      <th>Nome</th>
                      <th>Email</th>
                      <th>Status</th>
                      <th>Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in admins.data" :key="index">
                      <td>
                        <div class="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            :id="`customercheck${index}`"
                          />
                          <label class="custom-control-label" :for="`customercheck${index}`">&nbsp;</label>
                        </div>
                      </td>
                      <td>{{item.name}}</td>
                      <td>{{item.email}}</td>
                      <td>
                        <b-form-checkbox switch v-model="item.checked" @change="changeStatus(item.checked, item.admin_id)" size="lg"></b-form-checkbox>
                      </td>
                      <td>
                        <router-link
                          class="mr-3 text-primary"
                          v-b-tooltip.hover
                          title="Editar"
                          :to="`/admin/${item.admin_id}`"
                        >
                          <i class="mdi mdi-pencil font-size-18"></i>
                        </router-link>
                        <a
                          class="text-danger"
                          v-b-tooltip.hover
                          title="Excluir"
                          @click="deleteAdmin(item.admin_id)"
                        >
                          <i class="mdi mdi-trash-can font-size-18"></i>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </b-overlay>

              <b-pagination
                class="mt-4"
                v-model="admins.current_page"
                :total-rows="admins.total"
                :per-page="admins.per_page"
                aria-controls="my-table"
                align="center"
                @change="paginate"
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal
      id="modal-1"
      v-model="showmodal"
      title="Adicionar administrador"
      title-class="text-dark font-18"
      hide-footer
    >
      <form @submit.prevent="handleSubmit">
        <div class="form-group">
          <label for="name">Nome</label>
          <input
            id="name"
            v-model="customers.name"
            type="text"
            class="form-control"
            placeholder="Nome"
            :class="{ 'is-invalid': submitted && $v.customers.name.$error }"
          />
          <div
            v-if="submitted && !$v.customers.name.required"
            class="invalid-feedback"
          >O nome é obrigatório</div>
        </div>
        <div class="form-group">
          <label for="exampleInputEmail1">Email</label>
          <input
            id="email"
            v-model="customers.email"
            type="email"
            name="email"
            class="form-control"
            placeholder="Email"
            :class="{ 'is-invalid': submitted && $v.customers.email.$error }"
          />
          <div v-if="submitted && $v.customers.email.$error" class="invalid-feedback">
            <span v-if="!$v.customers.email.required">Email é obrigatório</span>
            <span v-if="!$v.customers.email.email">Email inválido</span>
          </div>
        </div>
        <div class="form-group">
          <label for="position">Senha</label>
          <input
            id="position"
            v-model="customers.password"
            type="password"
            class="form-control"
            placeholder="Senha"
            :class="{ 'is-invalid': submitted && $v.customers.password.$error }"
          />
          <div
            v-if="submitted && !$v.customers.password.required"
            class="invalid-feedback"
          >A senha é obrigatória</div>
          <div
            v-if="submitted && !$v.customers.password.minLength"
            class="invalid-feedback"
          >A senha deve conter no mínimo 6 caracteres</div>
        </div>
        
        <div class="text-right">
          <button type="submit" class="btn btn-success">Salvar</button>
          <b-button class="ml-1" variant="danger" @click="hideModal">Cancelar</b-button>
        </div>
      </form>
    </b-modal>
    <!-- end modal -->
  </Layout>
</template>